import { useState, useEffect } from 'react';
import {
  rank,
  customerStatus,
  memberStatus,
  budget,
  budgetMax,
  breadth,
  income,
  phoneHistorySelect,
  mailSendHistorySelect,
  yesno,
  floorPlan,
  holiday,
  isMail,
  supportKeyword,
  nonContactKeyword,
  nonContact,
  keywords,
  canCallTime,
  SEARCH_TYPE_CODE,
  MAIL_OPEN_HISTORY_CODE,
} from '../../../constants/search/detailSearch';
import store from '../../../store';
import { SYSTEM, SALES_GROUPS, MARKETING_GROUPS } from '../../../constants/userRoleGroup';
import {
  EMAIL_STATUS_AUTO, EMAIL_STATUS_GROUP,
  CURRENT_SITUATION_TYPE,
  CURRENT_SITUATION_TYPE_NO_BLANK,
  ACQUISITION_CODE,
} from '../../../constants';

const DetailSearchItem = (props) => {
  const {
    search,
    setSearch,
    set,
    setMulti,
    isOpenArea,
    setIsOpenArea,
    isOpenStation,
    setIsOpenStation,
    isOpenUserTree,
    setIsOpenUserTree,
    isAvoidSharedCustomer,
    setKeepDisabled,
    res,
    setRes,
    isFromAllmail,
  } = props;

  const { responseHeader } = store.getState();
  const { roleGroupId } = responseHeader;

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [open15, setOpen15] = useState(false);
  const [open16, setOpen16] = useState(false);
  const [open17, setOpen17] = useState(false);
  const [open18, setOpen18] = useState(false);
  const [open19, setOpen19] = useState(false);
  const [open20, setOpen20] = useState(false);
  const [open21, setOpen21] = useState(false);
  const [open22, setOpen22] = useState(false);
  const [open23, setOpen23] = useState(false);
  const [open24, setOpen24] = useState(false);
  const [open25, setOpen25] = useState(false);
  const [open26, setOpen26] = useState(false);
  const [open27, setOpen27] = useState(false);
  const [open28, setOpen28] = useState(false);
  const [open29, setOpen29] = useState(false);
  const [open30, setOpen30] = useState(false);
  const [open32, setOpen32] = useState(false);
  const [isOpenSelectResponse, setIsOpenSelectResponse] = useState(false);
  const [open33, setOpen33] = useState(false);
  const [open35, setOpen35] = useState(false); // wishRailwayIdSearchTypeCode
  const [open36, setOpen36] = useState(false); // searchStationTypeCode
  const [isOpenAbodeNowBuildingType, setIsOpenAbodeNowBuildingType] = useState(false);
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const [supportDisabled, setSupportDisabled] = useState(search.actionFrom === '');
  const [supportRequired, setSupportRequired] = useState(!(search.actionFrom === ''));
  const [supportActionCode, setSupportActionCode] = useState(search.actions?.length);

  // 住居種類の初期値を取得
  // abodeNowBuildingTypeはstringのためstringで比較
  const abodeNowBuildingTypeInitial = Object.keys(CURRENT_SITUATION_TYPE).find((key) => {
    return CURRENT_SITUATION_TYPE[key] === search.abodeNowBuildingType;
  }) || '';

  // Type=calendar FromToで上限下限値が逆転しないか判定
  const setFromToDate = (key, value, isTo, minMaxVal) => {
    if (!minMaxVal) { set(key, value); }
    const thisValue = new Date(value);
    const comparisonValue = new Date(minMaxVal);
    if (isTo) {
      if (!(thisValue < comparisonValue)) {
        set(key, value);
      }
    } else if (!(thisValue > comparisonValue)) {
      set(key, value);
    }
    // 対応履歴の表示制御
    if (key === 'actionFrom') {
      if (value) {
        setSupportDisabled(false);
        setSupportRequired(true);
      } else {
        setSupportDisabled(true);
        setSupportRequired(false);
      }
    }
  };

  useEffect(() => {
    // 追わない理由を選択時、ステータスを追わないにする
    if (search.noContactDetailReasonCode.length > 0) {
      // 追わない
      setSearch({ ...search, customerStatusCode: 2 });
    } else {
      // 追客中
      setSearch({ ...search, customerStatusCode: 1 });
    }
  }, [search.noContactDetailReasonCode]);

  useEffect(() => {
    // リセット時の対応
    if (!search.actionFrom) {
      setSupportDisabled(true);
      setSupportRequired(false);
      setSupportActionCode(false);
    }
  }, [search]);

  // 種類/反応
  const setActionCode = (value) => {
    if (value.actions?.length) {
      setSupportActionCode(true);
    } else {
      setSupportActionCode(false);
    }
  };

  // 一斉メールから開いた場合の各種処理
  // メールステータスのdisableを管理するstate
  const [emailStatusDisable, setEmailStatusDisable] = useState(false);
  useEffect(() => {
    if (search.isWebSearchOption) {
      // WEB動向検索オプションにチェックが入っている
      set('emailStatusCodes', []);
      setEmailStatusDisable(true);
    } else {
      // WEB動向検索オプションにチェックが入っていない
      setEmailStatusDisable(false);
      if (isFromAllmail && (SYSTEM === roleGroupId || MARKETING_GROUPS.includes(roleGroupId))) {
        // 一斉メール画面から開いた情報システム権限orマーケ権限→代行も～のみチェック
        set('emailStatusCodes', [EMAIL_STATUS_AUTO]);
      } else if (isFromAllmail && SALES_GROUPS.includes(roleGroupId)) {
        // 一斉メール画面から開いた営業権限→一斉・個別/代行も～にチェック
        set('emailStatusCodes', [EMAIL_STATUS_GROUP, EMAIL_STATUS_AUTO]);
      }
    }
  }, [search.isWebSearchOption]);
  // 一斉メールから開いた場合のWEB動向検索オプションまわりの挙動
  let isWebSearchOptionTooltip = '';
  if (SYSTEM === roleGroupId) {
    isWebSearchOptionTooltip = 'こちらがチェック状態の場合の検索条件\n ・条件1：下記いずれかの条件を満たす顧客は表示しない\n ・A.メールステータス：「代行も全てOK」、且つ「物件WEB物件閲覧履歴日が30日以内」の顧客\n ・B.メールステータス：「代行も全てOK」、且つ「最終資料請求日が14日以内」の顧客\n ・C.メールステータス：「代行も全てOK」、且つ「最終資料請求日が14日以内」の顧客';
  } else if (SALES_GROUPS.includes(roleGroupId)) {
    isWebSearchOptionTooltip = 'こちらがチェック状態の場合の検索条件\n ・条件１：メールステータス：「一斉・個別OK」の顧客を表示する\n ・条件２：下記いずれかの条件を満たす顧客は表示しない\n ・A.メールステータス：「代行も全てOK」、且つ「物件WEB物件閲覧履歴日が30日以内」の顧客\n ・B.メールステータス：「代行も全てOK」、且つ「最終資料請求日が14日以内」の顧客\n ・C.メールステータス：「代行も全てOK」、且つ「最終資料請求日が14日以内」の顧客';
  } else if (MARKETING_GROUPS.includes(roleGroupId)) {
    isWebSearchOptionTooltip = 'こちらがチェック状態の場合の検索条件\n ・条件１：メールステータス：「代行も全てOK」の顧客を表示する\n ・条件２：条件１の中で下記いずれかの条件を満たす顧客は表示しない\n ・A.メールステータス：「代行も全てOK」、且つ「物件WEB物件閲覧履歴日が30日以内」の顧客\n ・B.メールステータス：「代行も全てOK」、且つ「最終資料請求日が14日以内」の顧客\n ・C.メールステータス：「代行も全てOK」、且つ「最終見学予約日が14日以内」の顧客';
  }

  // 保存ボタンの必須制御
  useEffect(() => {
    if (supportRequired && !supportActionCode) {
      setKeepDisabled(true);
    } else {
      setKeepDisabled(false);
    }
  }, [supportRequired, supportActionCode]);

  // customerId用setState set('customerIds', e.trim().split(',').map(i => i));
  const setCustomerIds = (values) => {
    const result = values.trim().replaceAll('\n', ',').split(',').map(i => Number(i));
    if (result[result.length - 1] === 0) result.pop();
    set('customerIds', result);
  };

  useEffect(() => {
    if (!search.actionFrom) {
      setMulti({
        actions: [],
        actionKeyWord: '',
        actionSearchCode: [],
      });
    }
  }, [search.actionFrom]);

  const box = {
    important: {
      label: '重要情報',
      key: 'important',
      className: 'important',
      items: [
        {
          type: 'selectGroup',
          key: 'rank',
          open: open1,
          setOpen: setOpen1,
          className: 'important__selectContainer',
          input: {
            label: 'ランク（課員）',
            state: search.userLevelMin,
          },
          items: [
            {
              type: 'select',
              key: 'userLevelMin',
              input: {
                label: 'ランク（課員）',
                constants: rank,
                state: search.userLevelMin,
                upperState: search.userLevelMax,
                className: 'important__rank__medium',
                setState: (e) => { return set('userLevelMin', e); },
              },
            },
            {
              type: 'select',
              key: 'userLevelMax',
              before: '～',
              input: {
                label: '',
                constants: rank,
                state: search.userLevelMax,
                lowerState: search.userLevelMin,
                className: 'important__rank__medium',
                setState: (e) => { return set('userLevelMax', e); },
              },
            },
          ],
        },
        {
          type: 'boolean',
          key: 'isWebHistoryExist',
          className: 'important__checkboxOption__md',
          input: {
            label: 'Web履歴あり',
            state: search.isWebHistoryExist,
            setState: (e) => { return set('isWebHistoryExist', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isCallablePhoneNumberExist',
          className: 'important__checkboxOption__md',
          input: {
            label: 'TELあり',
            state: search.isCallablePhoneNumberExist,
            setState: (e) => { return set('isCallablePhoneNumberExist', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isSendableMailExist',
          className: 'important__checkboxOption__md',
          input: {
            label: 'Mailあり',
            state: search.isSendableMailExist,
            setState: (e) => { return set('isSendableMailExist', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isDirectExist',
          className: 'important__checkboxOption__md',
          input: {
            label: '指示あり',
            state: search.isDirectExist,
            setState: (e) => { return set('isDirectExist', e || null); },
          },
        },
        {
          type: 'select',
          key: 'customerStatusCode',
          className: 'important__customerStatusCode',
          width: 30,
          open: open2,
          setOpen: setOpen2,
          input: {
            label: '追跡状況',
            constants: customerStatus,
            state: search.customerStatusCode,
            setState: (e) => { return set('customerStatusCode', e); },
          },
        },
        {
          type: 'boolean',
          key: 'isSendShare',
          className: 'important__checkboxOption__lg',
          input: {
            label: '共有してる顧客のみ',
            state: !isAvoidSharedCustomer && search.isSendShare,
            setState: (e) => { return set('isSendShare', e || null); },
            disabled: isAvoidSharedCustomer,
          },
        },
        {
          type: 'boolean',
          key: 'isReceiveShare',
          className: 'important__checkboxOption__lg',
          input: {
            label: '共有された顧客のみ',
            state: !isAvoidSharedCustomer && search.isReceiveShare,
            setState: (e) => { return set('isReceiveShare', e || null); },
            disabled: isAvoidSharedCustomer,
          },
        },
      ],
    },
    response: {
      label: '反響情報',
      key: 'response',
      className: 'response',
      items: [
        {
          type: 'calendar',
          key: 'responseCalendar',
          width: 50,
          open: open3,
          setOpen: setOpen3,
          className: 'response__calendarContainer',
          input: {
            key: 'responseCalendar',
            label: ['反響日'],
            keys: ['effectFrom', 'effectTo'],
            constants: memberStatus,
            state: search.effectFrom,
            stateto: search.effectTo,
            className1: 'response__calendarFrom',
            className2: 'response__calendarTo',
            className3: 'response__calendarLabel',
            setState: (e) => { return setFromToDate('effectFrom', e, false, search.effectTo); },
            setStateto: (e) => { return setFromToDate('effectTo', e, true, search.effectFrom); },
          },
        },
        {
          type: 'select',
          key: 'memberStatusCode',
          width: 25,
          open: open4,
          setOpen: setOpen4,
          className: 'response__webMemberStatusContainer',
          input: {
            label: '会員状態',
            constants: memberStatus,
            state: search.memberStatusCode,
            setState: (e) => { return set('memberStatusCode', e); },
          },
        },
        {
          type: 'acquisition',
          key: 'acquisitionName',
          width: 25,
          open: open5,
          setOpen: setOpen5,
          className: 'response__acquisitionName',
          input: {
            label: '源泉獲得者',
            state: search.acquisitionName,
            setState: (e) => {
              return setMulti(e);
            },
          },
        },
        {
          type: 'label',
          key: 'acquisitionCode',
          width: 25,
          className: 'response__acquisitionCode',
          input: {
            label: '獲得者種',
            state: ACQUISITION_CODE[(search.acquisitions?.acquisitionCode)],
          },
        },
        {
          type: 'response',
          key: 'responseCode1Sp',
          responsive: 'onlySp',
          open: isOpenSelectResponse,
          setOpen: setIsOpenSelectResponse,
          className: 'response__responseCode1Container',
          input: {
            label: '反響媒体',
            list: ['媒体（大）', '媒体（中）', '媒体（小）', '媒体（細）'],
            keys: [
              'largeResponseTypeId',
              'mediumResponseTypeId',
              'smallResponseTypeId',
              'fineResponseTypeId',
              'responseTypeList',
            ],
            state: res,
            setState: (e) => {
              if (e.length > 0) {
                setRes(e.map(obj => ({ ...obj })));
              } else {
                setRes(e);
              }
            },
            func: (e) => {
              const copyObj = { ...search };
              copyObj.largeResponseTypeId = e[0]?.responseTypeId || '';
              copyObj.mediumResponseTypeId = e[1]?.responseTypeId || '';
              copyObj.smallResponseTypeId = e[2]?.responseTypeId || '';
              copyObj.fineResponseTypeId = e[3]?.responseTypeId || '';
              copyObj.responseTypeList = e;
              setSearch(copyObj);
            },
          },
        },
        {
          type: 'response',
          responsive: 'onlyPc',
          key: 'responseCode1',
          width: 100,
          className: 'response__responseCode1Container',
          input: {
            list: ['媒体（大）', '媒体（中）', '媒体（小）', '媒体（細）'],
            state: res,
            setState: (e) => {
              if (e.length > 0) {
                setRes(e.map(obj => ({ ...obj })));
              } else {
                setRes(e);
              }
            },
            func: (e) => {
              const copyObj = { ...search };
              copyObj.largeResponseTypeId = e[0]?.responseTypeId || '';
              copyObj.mediumResponseTypeId = e[1]?.responseTypeId || '';
              copyObj.smallResponseTypeId = e[2]?.responseTypeId || '';
              copyObj.fineResponseTypeId = e[3]?.responseTypeId || '';
              copyObj.responseTypeList = e;
              setSearch(copyObj);
            },
          },
        },
      ],
    },
    terms: {
      label: '希望条件',
      key: 'terms',
      className: 'terms',
      items: [
        {
          type: 'selectGroup',
          key: 'houseBudget',
          open: open8,
          className: 'terms__inputContainer__sm  terms__houseBudget',
          setOpen: setOpen8,
          input: {
            label: '戸建価格（万円）',
            state: search.houseBudgetMin, // 仮
          },
          items: [
            {
              type: 'select',
              key: 'houseBudgetMin',
              input: {
                label: '戸建価格（万円）',
                constants: budget,
                state: search.houseBudgetMin,
                upperState: search.houseBudgetMax,
                strValue: true,
                className: 'terms__input__select',
                setState: (e) => { return set('houseBudgetMin', e); },
              },
            },
            {
              type: 'select',
              key: 'houseBudgetMax',
              before: '～',
              input: {
                label: '',
                constants: budgetMax,
                state: search.houseBudgetMax,
                lowerState: search.houseBudgetMin,
                className: 'terms__input__select',
                setState: (e) => { return set('houseBudgetMax', e); },
              },
            },
          ],
        },
        {
          type: 'selectGroup',
          key: 'wishLiving',
          open: open9,
          setOpen: setOpen9,
          className: 'terms__inputContainer__sm terms__wishLiving',
          input: {
            label: '戸建広さ（平米）',
            state: search.livingSpaceMin, // 仮
          },
          items: [
            {
              type: 'select',
              key: 'livingSpaceMin',
              input: {
                label: '戸建広さ（平米）',
                constants: breadth,
                state: search.livingSpaceMin,
                upperState: search.livingSpaceMax,
                className: 'terms__input__select',
                setState: (e) => { return set('livingSpaceMin', e); },
              },
            },
            {
              type: 'select',
              key: 'livingSpaceMax',
              before: '～',
              input: {
                label: '',
                constants: breadth,
                state: search.livingSpaceMax,
                lowerState: search.livingSpaceMin,
                className: 'terms__input__select',
                setState: (e) => { return set('livingSpaceMax', e); },
              },
            },
          ],
        },
        {
          type: 'checkbox',
          key: 'floorPlan',
          open: open10,
          setOpen: setOpen10,
          className: 'terms__inputContainer__sm terms__floorPlan',
          input: {
            label: '間取り',
            after: '間取り',
            constants: floorPlan,
            state: search.floorPlan,
            setState: (e) => set('floorPlan', e),
          },
        },
        {
          type: 'selectGroup',
          key: 'landBudget',
          open: open11,
          setOpen: setOpen11,
          className: 'terms__inputContainer__sm terms__landBudget',
          input: {
            label: '土地価格（万円）',
            state: search.landBudgetMin, // 仮
          },
          items: [
            {
              type: 'select',
              key: 'landBudgetMin',
              input: {
                label: '土地価格（万円）',
                constants: budget,
                state: search.landBudgetMin,
                upperState: search.landBudgetMax,
                className: 'terms__input__select',
                setState: (e) => { return set('landBudgetMin', e); },
              },
            },
            {
              type: 'select',
              key: 'landBudgetMax',
              before: '～',
              input: {
                label: '',
                constants: budgetMax,
                state: search.landBudgetMax,
                lowerState: search.landBudgetMin,
                className: 'terms__input__select',
                setState: (e) => { return set('landBudgetMax', e); },
              },
            },
          ],
        },
        {
          type: 'selectGroup',
          key: 'wishAreaSize',
          open: open12,
          setOpen: setOpen12,
          className: 'terms__inputContainer__sm terms__wishAreaSize',
          input: {
            label: '土地広さ（平米）',
            state: search.wishLandAreaMin, // 仮
          },
          items: [
            {
              type: 'select',
              key: 'wishLandAreaMin',
              input: {
                label: '土地広さ（平米）',
                constants: breadth,
                state: search.wishLandAreaMin,
                upperState: search.wishLandAreaMax,
                className: 'terms__input__select',
                setState: (e) => { return set('wishLandAreaMin', e); },
              },
            },
            {
              type: 'select',
              key: 'wishLandAreaMax',
              before: '～',
              input: {
                label: '',
                constants: breadth,
                state: search.wishLandAreaMax,
                lowerState: search.wishLandAreaMin,
                className: 'terms__input__select',
                setState: (e) => { return set('wishLandAreaMax', e); },
              },
            },
          ],
        },
        {
          type: 'selectGroup',
          key: 'annualIncome',
          open: open13,
          setOpen: setOpen13,
          className: 'terms__inputContainer__sm terms__annualIncome',
          input: {
            label: '年収（万円）',
            state: search.annualIncomeMin, // 仮
          },
          items: [
            {
              type: 'select',
              key: 'annualIncomeMin',
              input: {
                label: '年収（万円）',
                constants: income,
                state: search.annualIncomeMin,
                upperState: search.annualIncomeMax,
                className: 'terms__input__select',
                setState: (e) => { return set('annualIncomeMin', e); },
              },
            },
            {
              type: 'select',
              key: 'annualIncomeMax',
              before: '～',
              input: {
                label: '',
                constants: income,
                state: search.annualIncomeMax,
                lowerState: search.annualIncomeMin,
                className: 'terms__input__select',
                setState: (e) => { return set('annualIncomeMax', e); },
              },
            },
          ],
        },
        {
          type: 'popup',
          key: 'wishArea',
          open: isOpenArea,
          setOpen: setIsOpenArea,
          className: 'terms__inputContainer__xs1 terms__wishArea',
          input: {
            label: '地域',
            state: search.wishArea,
            setState: (e) => { return set('wishArea', e); },
          },
        },
        {
          type: 'hidden',
          key: 'wishArea',
          open: isOpenArea,
          setOpen: setIsOpenArea,
          className: 'terms__wishArea',
          input: {
            label: '地域',
            wishAreaIds: search.wishAreaIds,
            wishAreaCode: search.wishAreaCode,
            setState: (e) => { return setMulti(e); },
          },
        },
        {
          type: 'select',
          key: 'wishAreaSearchTypeCode',
          className: 'terms__inputContainer__xs2 terms__wishAreaSearchTypeCode',
          setOpen: setOpen33,
          open: open33,
          input: {
            label: '',
            constants: SEARCH_TYPE_CODE,
            state: search.wishAreaSearchTypeCode,
            setState: (e) => { return set('wishAreaSearchTypeCode', e); },
          },
        },
        {
          type: 'hidden',
          key: 'wishRailwayId',
          open: isOpenStation,
          setOpen: setIsOpenStation,
          className: 'terms__wishRailwayId',
          input: {
            label: '沿線',
            state: search.wishRailwayId,
            setState: (e) => { return set('wishRailwayId', e); },
          },
        },
        {
          type: 'popup',
          key: 'wishRailway',
          open: isOpenStation,
          setOpen: setIsOpenStation,
          className: 'terms__inputContainer__xs1 terms__wishRailway',
          input: {
            label: '沿線',
            state: search.wishRailway,
            setState: (e) => { return set('wishRailway', e); },
          },
        },
        {
          type: 'popup',
          responsive: 'onlyPc',
          key: 'wishStationFromName',
          open: isOpenStation,
          setOpen: setIsOpenStation,
          className: 'terms__inputContainer__xs1 terms__wishRailway',
          input: {
            label: '駅(From)',
            state: search.wishStationFromName,
            setState: (e) => { return set('wishStationFromName', e); },
          },
        },
        {
          type: 'popup',
          responsive: 'onlyPc',
          key: 'wishStationToName',
          open: isOpenStation,
          setOpen: setIsOpenStation,
          className: 'terms__inputContainer__xs1 terms__wishRailway',
          input: {
            label: '駅(To)',
            state: search.wishStationToName,
            setState: (e) => { return set('wishStationToName', e); },
          },
        },
        {
          type: 'textGroup',
          key: 'wishStationFrom',
          className: 'terms__wishStationFrom',
          open: isOpenStation,
          setOpen: setIsOpenStation,
          input: {
            label: '駅',
            place: '駅(From)',
            state: search.wishStationFrom,
            setState: (e) => { return set('wishStationFrom', e); },
            readOnly: true,
          },
          items: [
            {
              type: 'hidden',
              key: 'wishStationFrom',
              className: 'terms__wishStationFrom',
              input: {
                label: '駅',
                place: '駅(From)',
                state: search.wishStationFromName,
                setState: (e) => { return set('wishStationFromName', e); },
                readOnly: true,
              },
            },
            {
              type: 'hidden',
              key: 'wishStationTo',
              className: 'terms__wishStationTo',
              input: {
                label: '駅',
                place: '駅(To)',
                state: search.wishStationToName,
                setState: (e) => { return set('wishStationToName', e); },
                readOnly: true,
              },
            },
          ],
        },
        {
          type: 'select',
          key: 'searchStationTypeCode',
          className: 'terms__inputContainer__xxs1 terms__searchStationTypeCode',
          setOpen: setOpen35,
          open: open35,
          input: {
            label: '',
            constants: SEARCH_TYPE_CODE,
            state: search.searchStationTypeCode,
            setState: (e) => { return set('searchStationTypeCode', e); },
          },
        },
      ],
    },
    termsType: {
      label: '',
      key: 'termsType',
      className: 'termsType',
      items: [
        {
          type: 'boolean',
          key: 'isThinkSale',
          className: 'termsType__item',
          input: {
            label: '売却',
            state: search.isThinkSale,
            setState: (e) => { return set('isThinkSale', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isThinkApartment',
          className: 'termsType__item',
          input: {
            label: 'マンション',
            state: search.isThinkApartment,
            setState: (e) => { return set('isThinkApartment', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isThinkWholeHouseInvestment',
          className: 'termsType__item',
          input: {
            label: '一棟物投資',
            state: search.isThinkWholeHouseInvestment,
            setState: (e) => { return set('isThinkWholeHouseInvestment', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isThinkDivisionInvestment',
          className: 'termsType__item',
          input: {
            label: '区分投資',
            state: search.isThinkDivisionInvestment,
            setState: (e) => { return set('isThinkDivisionInvestment', e || null); },
          },
        },
        {
          type: 'boolean',
          key: 'isThinkRebuilding',
          className: 'termsType__item',
          input: {
            label: '建替',
            state: search.isThinkRebuilding,
            setState: (e) => { return set('isThinkRebuilding', e || null); },
          },
        },
      ],
    },
    achievement: {
      label: '行動情報',
      key: 'achievement',
      className: 'achievement',
      items: [
        {
          type: 'select',
          key: 'phoneSupportStatusCode',
          open: open14,
          setOpen: setOpen14,
          input: {
            label: 'TEL未対応',
            constants: phoneHistorySelect,
            state: search.phoneSupportStatusCode,
            setState: (e) => { return set('phoneSupportStatusCode', e); },
          },
        },
        {
          type: 'select',
          key: 'mailSendStatusCode',
          open: open15,
          setOpen: setOpen15,
          input: {
            label: 'Mail未送信',
            constants: mailSendHistorySelect,
            state: search.mailSendStatusCode,
            setState: (e) => { return set('mailSendStatusCode', e); },
          },
        },
        {
          type: 'select',
          key: 'isGuidanceHistorySearch',
          open: open16,
          setOpen: setOpen16,
          input: {
            label: '案内実績',
            constants: yesno,
            state: search.isGuidanceHistorySearch,
            setState: (e) => { return set('isGuidanceHistorySearch', e); },
          },
        },
        {
          type: 'select',
          key: 'isVisitHistorySearch',
          open: open17,
          setOpen: setOpen17,
          input: {
            label: '来社実績',
            constants: yesno,
            state: search.isVisitHistorySearch,
            setState: (e) => { return set('isVisitHistorySearch', e); },
          },
        },
        {
          type: 'select',
          key: 'mailOpenHistoryCode',
          open: open18,
          setOpen: setOpen18,
          input: {
            label: 'メール開封履歴',
            constants: MAIL_OPEN_HISTORY_CODE,
            state: search.mailOpenHistoryCode,
            setState: (e) => { return set('mailOpenHistoryCode', e); },
          },
        },
      ],
    },
    supplement: {
      label: '補足情報',
      key: 'supplement',
      className: 'supplement',
      items: [
        {
          type: 'checkbox',
          responsive: 'onlyPc',
          key: 'holidayWeekdays',
          className: 'supplement__holidayWeekdays',
          input: {
            label: 'お休みの日',
            after: 'お休みの日',
            constants: holiday,
            strValue: true,
            state: search.holidayWeekdays,
            setState: (e) => { return set('holidayWeekdays', e.map(i => String(i))); },
          },
        },
        {
          type: 'itemGroup',
          label: 'お休みの日',
          responsive: 'onlySp',
          key: 'holidayWeekdaySp',
          open: open19,
          setOpen: setOpen19,
          items: [
            {
              type: 'checkbox',
              key: 'holidayWeekdays',
              input: {
                label: 'お休みの日',
                after: 'お休みの日',
                constants: holiday,
                strValue: true,
                state: search.holidayWeekdays,
                setState: (e) => { return set('holidayWeekdays', e.map(i => String(i))); },
              },
            },
            {
              type: 'text',
              key: 'holidayWeekdayOthers',
              width: 100,
              input: {
                after: 'その他',
                place: 'その他',
                state: search.holidayWeekdayOthers,
                setState: (e) => { return set('holidayWeekdayOthers', e); },
              },
            },
          ],
        },
        {
          type: 'checkbox',
          key: 'emailStatusCodes',
          open: open21,
          setOpen: setOpen21,
          className: 'supplement__emailStatusCodes',
          input: {
            label: 'メール許可',
            before: 'メール許可',
            constants: isMail,
            state: search.emailStatusCodes,
            setState: (e) => { return set('emailStatusCodes', e); },
            disabled: emailStatusDisable,
          },
        },
        {
          type: 'boolean',
          key: 'isWebSearchOption',
          className: 'important__checkboxOption__md',
          responsive: 'onlyPc',
          input: {
            label: 'WEB動向検索オプション',
            before: 'マーケメール施策',
            state: search.isWebSearchOption,
            setState: (e) => { return set('isWebSearchOption', e === 1); },
            tooltip: isWebSearchOptionTooltip,
          },
        },
        {
          type: 'select',
          key: 'telStatusCode',
          open: open20,
          setOpen: setOpen20,
          className: 'supplement__telStatusCode',
          input: {
            label: '電話許可',
            constants: yesno,
            state: search.telStatusCode,
            setState: (e) => { return set('telStatusCode', e); },
          },
        },
        {
          type: 'select',
          key: 'isAllowDm',
          open: open22,
          setOpen: setOpen22,
          className: 'supplement__isAllowDm',
          input: {
            label: 'DM許可',
            constants: yesno,
            state: search.isAllowDm,
            className: 'terms__input__select',
            setState: (e) => { return set('isAllowDm', e); },
          },
        },
        {
          type: 'select',
          key: 'isAddressExist',
          open: open23,
          setOpen: setOpen23,
          className: 'supplement__isAddressExist',
          input: {
            label: '住所有無',
            constants: yesno,
            state: search.isAddressExist,
            className: 'terms__input__select',
            setState: (e) => { return set('isAddressExist', e); },
          },
        },
        {
          type: 'select',
          key: 'abodeNowBuildingType',
          responsive: 'onlyPc',
          open: isOpenAbodeNowBuildingType,
          setOpen: setIsOpenAbodeNowBuildingType,
          className: 'supplement__abodeNowBuildingType',
          input: {
            label: '現況住居種類',
            constants: {
              menus: CURRENT_SITUATION_TYPE_NO_BLANK,
            },
            strValue: true,
            state: abodeNowBuildingTypeInitial,
            className: 'terms__input__select',
            setState: (e) => { return set('abodeNowBuildingType', e); },
          },
        },
        {
          type: 'select',
          key: 'abodeNowBuildingType',
          responsive: 'onlySp',
          open: isOpenAbodeNowBuildingType,
          setOpen: setIsOpenAbodeNowBuildingType,
          className: 'supplement__abodeNowBuildingType',
          input: {
            label: '現況住居種類',
            constants: {
              menus: CURRENT_SITUATION_TYPE_NO_BLANK,
            },
            strValue: true,
            state: abodeNowBuildingTypeInitial,
            className: 'terms__input__select',
            setState: (e) => {
              return set('abodeNowBuildingType', CURRENT_SITUATION_TYPE_NO_BLANK[e]);
            },
          },
        },
        {
          type: 'popup',
          key: 'currentUser',
          open: isOpenUserTree,
          setOpen: setIsOpenUserTree,
          className: 'supplement__currentUser',
          width: 30,
          input: {
            label: '現担当',
            state: search.currentUser,
            setState: (e) => { return set('currentUser', e); },
          },
        },
        {
          type: 'hidden',
          key: 'currentUserId',
          className: 'supplement__currentUserId',
          width: 30,
          input: {
            label: '現担当',
            state: search.currentUserId,
            setState: (e) => { return set('currentUserId', e); },
          },
        },
        {
          type: 'popup',
          key: 'formerUser',
          open: isOpenUserTree,
          setOpen: setIsOpenUserTree,
          className: 'supplement__formerUser',
          width: 30,
          input: {
            label: '旧担当',
            state: search.formerUser,
            setState: (e) => { return set('formerUser', e); },
          },
        },
        {
          type: 'hidden',
          key: 'formerUserId',
          width: 30,
          className: 'supplement__formerUserId',
          input: {
            label: '旧担当',
            state: search.formerUserId,
            setState: (e) => { return set('formerUserId', e); },
          },
        },
        {
          type: 'popup',
          key: 'previousUser',
          open: isOpenUserTree,
          setOpen: setIsOpenUserTree,
          className: 'supplement__previousUser',
          width: 30,
          input: {
            label: '前担当',
            state: search.previousUser,
            setState: (e) => { return set('previousUser', e); },
          },
        },
        {
          type: 'hidden',
          key: 'previousUserId',
          className: 'supplement__previousUserId',
          width: 30,
          input: {
            label: '前担当',
            state: search.previousUserId,
            setState: (e) => { return set('previousUserId', e); },
          },
        },
      ],
    },
    support: {
      label: '対応履歴',
      key: 'support',
      className: 'support',
      items: [
        {
          type: 'calendar',
          key: 'actionCalendar',
          width: 50,
          open: open24,
          setOpen: setOpen24,
          className: 'support__actionCalendar',
          input: {
            key: 'actionCalendar',
            keys: ['actionFrom', 'actionTo'],
            label: ['対応日時'],
            constants: memberStatus,
            state: search.actionFrom,
            stateto: search.actionTo,
            className1: 'support__calendarFrom',
            className2: 'support__calendarTo',
            className3: 'support__calendarLabel',
            setState: (e) => { return setFromToDate('actionFrom', e, false, search.actionTo); },
            setStateto: (e) => { return setFromToDate('actionTo', e, true, search.actionFrom); },
            disabled: supportDisabled,
            setSupportDisabled,
          },
        },
        {
          type: 'checkboxwindow',
          key: 'actionCode',
          width: 50,
          className: 'support__actionCode',
          input: {
            label: '種類/反応',
            state: search.actions,
            setState: (e) => { setMulti(e); setActionCode(e); },
            disabled: supportDisabled,
            required: supportRequired,
          },
        },
        {
          type: 'text',
          key: 'actionKeyWord',
          responsive: 'onlyPc',
          width: 100,
          open: open25,
          setOpen: setOpen25,
          className: 'support__actionKeyWord',
          input: {
            place: 'キーワード',
            state: search.actionKeyWord,
            className: 'support__actionKeyWord__input',
            setState: (e) => { return set('actionKeyWord', e); },
            disabled: supportDisabled,
          },
        },
        {
          type: 'checkbox',
          key: 'actionSearchCode',
          responsive: 'onlyPc',
          open: open26,
          setOpen: setOpen26,
          className: 'support__actionSearchCode',
          input: {
            label: 'キーワードの検索の範囲',
            after: 'キーワードの検索の範囲',
            checked: 1,
            constants: supportKeyword,
            state: search.actionSearchCode,
            setState: (e) => { return set('actionSearchCode', e); },
            disabled: supportDisabled,
          },
        },
        {
          type: 'itemGroup',
          label: 'キーワードの検索の範囲',
          responsive: 'onlySp',
          key: 'actionSearchCode',
          open: open26,
          setOpen: setOpen26,
          className: 'support__actionSearchCode',
          items: [
            {
              type: 'text',
              key: 'actionKeyWord',
              width: 100,
              input: {
                after: 'キーワード',
                place: 'キーワード',
                state: search.actionKeyWord,
                setState: (e) => {
                  return set('actionKeyWord', e);
                },
                disabled: supportDisabled,
              },
            },
            {
              type: 'checkbox',
              key: 'actionSearchCode',
              open: open26,
              setOpen: setOpen26,
              className: 'support__actionSearchCode',
              input: {
                label: 'キーワードの検索の範囲',
                after: 'キーワードの検索の範囲',
                checked: 1,
                constants: supportKeyword,
                state: search.actionSearchCode,
                setState: (e) => {
                  return set('actionSearchCode', e);
                },
                disabled: supportDisabled,
              },
            },
          ],
          input: {
            disabled: supportDisabled,
            state: search.actionKeyWord,
          },
        },
      ],
    },
    nocontact: {
      label: '追わない',
      key: 'nocontact',
      className: 'nocontact',
      items: [
        {
          type: 'calendar',
          key: 'thinkStartSeasonFrom',
          width: 50,
          open: open27,
          setOpen: setOpen27,
          className: 'nocontact__thinkStartSeasonFrom',
          input: {
            key: 'thinkStartSeasonFrom',
            keys: ['thinkStartSeasonFrom', 'thinkStartSeasonTo'],
            label: ['検討再開時期'],
            constants: memberStatus,
            state: search.thinkStartSeasonFrom,
            stateto: search.thinkStartSeasonTo,
            className1: 'nocontact__calendarFrom',
            className2: 'nocontact__calendarTo',
            className3: 'nocontact__calendarLabel',
            setState: (e) => { return setFromToDate('thinkStartSeasonFrom', e, false, search.thinkStartSeasonTo); },
            setStateto: (e) => { return setFromToDate('thinkStartSeasonTo', e, true, search.thinkStartSeasonFrom); },
          },
        },
        {
          type: 'checkbox',
          key: 'noContactDetailReasonCode',
          width: 50,
          open: open28,
          setOpen: setOpen28,
          className: 'nocontact__noContactReasonCode',
          input: {
            label: '追わない理由',
            after: '追わない理由',
            constants: nonContact,
            state: search.noContactDetailReasonCode,
            setState: (e) => { return set('noContactDetailReasonCode', e); },
          },
        },
        {
          type: 'text',
          responsive: 'onlyPc',
          key: 'noneContactKeyword',
          className: 'nocontact__noneContactKeyword',
          width: 100,
          input: {
            place: 'キーワード',
            state: search.noneContactKeyword,
            className: 'nocontact__noneContactKeyword__input',
            setState: (e) => { return set('noneContactKeyword', e); },
          },
        },
        {
          type: 'checkbox',
          responsive: 'onlyPc',
          key: 'noneContactKeywordCodes',
          className: 'nocontact__noneContactKeywordCodes',
          input: {
            label: 'キーワードの検索の範囲',
            after: 'キーワードの検索の範囲',
            checked: 1,
            constants: nonContactKeyword,
            state: search.noneContactKeywordCodes,
            setState: (e) => { return set('noneContactKeywordCodes', e); },
          },
        },
        {
          type: 'itemGroup',
          label: 'キーワード（追わない）',
          responsive: 'onlySp',
          key: 'noneContactKeywordSp',
          open: open29,
          setOpen: setOpen29,
          items: [
            {
              type: 'text',
              key: 'noneContactKeyword',
              width: 100,
              input: {
                after: 'キーワード',
                place: 'キーワード',
                state: search.noneContactKeyword,
                setState: (e) => { return set('noneContactKeyword', e); },
              },
            },
            {
              type: 'checkbox',
              key: 'noneContactKeywordCodes',
              input: {
                label: 'キーワードの検索の範囲',
                after: 'キーワードの検索の範囲',
                checked: 1,
                constants: nonContactKeyword,
                state: search.noneContactKeywordCodes,
                setState: (e) => { return set('noneContactKeywordCodes', e); },
              },
            },
          ],
        },
      ],
    },
    keyword: {
      label: `${!isSP ? 'キーワード（この項目を使うと検索が遅くなります）' : ''}`,
      key: 'keyword',
      className: 'keyword',
      items: [
        {
          type: 'textField',
          key: 'keyword',
          width: 100,
          className: 'keyword__keyword',
          input: {
            label: `${!isSP ? '' : 'キーワード検索'}`,
            place: 'キーワード',
            className: 'keyword__keyword__input',
            state: search.keyword,
            setState: (e) => { return set('keyword', e); },
          },
        },
        {
          type: 'checkbox',
          key: 'keywordSearch',
          open: open30,
          setOpen: setOpen30,
          className: 'keyword__keywordSearch',
          input: {
            label: 'キーワードの検索の範囲',
            after: 'キーワードの検索の範囲',
            checked: 1,
            constants: keywords,
            state: search.keywordSearch,
            setState: (e) => { return set('keywordSearch', e); },
          },
        },
      ],
    },
    customerId: {
      label: `${!isSP ? '顧客ID（半角カンマまたは改行で区切って入力してください）' : ''}`,
      key: 'customerId',
      className: 'customerId',
      items: [
        {
          type: 'text',
          key: 'customerIds',
          width: 100,
          label: '顧客ID',
          open: open32,
          setOpen: setOpen32,
          className: 'customerId__customerIds',
          input: {
            place: '顧客ID',
            type: 'number',
            multiline: true,
            state: search.customerIds,
            setState: (e) => { setCustomerIds(e); },
            className: 'customerId__customerIds__input',
          },
        },
      ],
    },
    tel: {
      label: `${!isSP ? 'TELが繋がりやすい時間帯' : ''}`,
      key: 'tel',
      className: 'tel',
      items: [
        {
          type: 'select',
          key: 'convenientTimes',
          open: open36,
          setOpen: setOpen36,
          label: 'TELが繋がりやすい時間帯',
          className: 'tel__convenientTimes',
          input: {
            label: '選択してください',
            constants: canCallTime,
            className: 'tel__convenientTimes__input',
            strValue: true,
            state: search.convenientTimes,
            setState: (e) => { return set('convenientTimes', e === '' ? e : [String(e)]); },
          },
        },
      ],
    },
  };

  // SPの場合は、順番変更(キーワードの項目を先頭にする)
  const initialList = [];
  let keyOreder;
  if (isSP) {
    keyOreder = ['keyword', 'important', 'response', 'terms', 'termsType', 'achievement', 'supplement', 'support', 'nocontact', 'customerId', 'tel'];
  } else {
    keyOreder = ['important', 'response', 'terms', 'termsType', 'achievement', 'supplement', 'support', 'nocontact', 'keyword', 'customerId', 'tel'];
  }

  for (const key of keyOreder) {
    initialList.push(box[key]);
  }

  return initialList;
};

export default DetailSearchItem;
