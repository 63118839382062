import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Drawer, Typography, Button } from '@material-ui/core';
import { Close, Add, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import dSearchLocalStyle from './style/detailSearchlocalStyle';
import SearchIcon from '../../icons/searchIcon';
import DetailSearchItem from './detailSearchItem';
import SearchPartsSelect from './parts/detailSearchSelect';
import SearchPartsBoolean from './parts/detailSearchBoolean';
import SearchPartsCheckbox from './parts/detailSearchCheckbox';
import SearchPartsText from './parts/detailSearchText';
import SearchPartsCalendar from './parts/detailSearchCalendar';
import SearchPartsCalendarWindow from './parts/sp/detailSearchCalendarWindow';
import SearchPartsResponse from './parts/detailSearchResponse';
import SearchCheckboxWindowComponent from './parts/detailSearchCheckboxWindowComp';
import CustomSimpleDrawer, { CustomDrawerList } from '../../eleCommon/sp/customSimpleDrawer';
import AreaSentaku from '../../common/areaSentaku';
import SelectRailwaysAndStationsContainer from '../../../containers/search/railwayAndStations/selectRailwayAndStations';
import UserTree from '../../common/userTreeNew';
import { updateSearchItems, setIsDetailSearch } from '../../../store/search/searchItemsSlice';
import { AREA_CODES } from '../../../constants/apiParameterCode';
import Dialog from '../../common/modal';
import { isNone } from '../../../commonFunction/';
import { useCustomerMain } from '../../../containers/customerMain';
import { useStore } from '../../../hooks';
import getFromAllCustomer from '../../common/customerTree/commonFunc/getListFromAllCustomer';
import SearchAcquisition from './parts/detailSearchAcquisition';
import SearchLabel from './parts/detailSearchLabel';

const useStyles = makeStyles((theme) => ({
  itemButton: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&>span': {
      fontSize: 13,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&.disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  button: {
    '&>span': {
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 13,
      },
    },
  },
  buttonLabel: {
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
      flexShrink: 0,
    },
  },
  buttonText: {
    marginLeft: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  buttonValue: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    '& > span': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:not(:first-child)': {
        marginTop: 8,
      },
    },
    '& + svg': {
      marginLeft: 8,
    },
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  wrap: {
    padding: '24px',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
    overflowY: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      height: '70vh',
    },
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 38,
    },
  },
  childwrap: {
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      marginTop: '8px',
      padding: '8px',
      border: '1px #c8c8c8 solid',
    },
  },
  listWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingRight: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
  reset: {
    color: theme.palette.primaryColor,
  },
  itemwrap: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: '8px',
      '&[data-before]::before': {
        content: 'attr(data-before)',
        width: '2rem',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&[data-width="30"]': {
        width: '30%',
      },
      '&[data-width="50"]': {
        width: '50%',
      },
      '&[data-width="100"]': {
        width: '100%',
      },
      '&.onlySp': {
        display: 'none',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      '&.onlyPc': {
        display: 'none',
      },
    },
    '& .MuiFormControl-root': { width: '100%' },
  },
  flex: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 16,
    },
  },
  loopChildItems: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 16,
    },
  },
  box: {
    width: '100%',
    '&:not(:first-child) > p': {
      marginTop: '24px',
      [commonTheme.breakpoints.down('sm')]: {
        marginBottom: '8px',
      },
      '&:empty': {
        [commonTheme.breakpoints.up('md')]: {
          marginTop: '8px',
        },
        [commonTheme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  },
  popupbtn: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    width: '100%',
    borderBottom: '1px #8c8c8c solid',
    borderRadius: 0,
    background: 'transparent top 50% right 5px/17px no-repeat',
    backgroundImage:
      'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: '#000',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  drawer: {
    position: 'relative',
    '& .MuiPaper-root': {
      width: 'calc(100% - 50px)',
      overflow: 'visible',
      boxShadow: 'none',
    },
  },
  searchSmallContainer: {
    '& > .MuiFormGroup-root': {
      [commonTheme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        width: '100%',
      },
    },
  },
  searchDrawerHeader: {
    padding: '19px 8px 8px',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
  },
  searchDrawerList: {
    paddingLeft: 16,
    paddingBottom: 91,
    height: 'calc(100vh - 91px)',
    overflowY: 'auto',
  },
  searchDrawerItem: {
    padding: '24px 8px 13px 16px',
    borderBottom: '1px solid #F3F3F3',
  },
  searchDrawerBtnWrap: {
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid rgba(51, 51, 51, 0.04)',
    position: 'fixed',
    right: 0,
    bottom: 0,
    padding: '24px 0',
    [commonTheme.breakpoints.down('sm')]: {
      width: 'calc(100% - 50px)',
    },
  },
  searchDrawerBtn: {
    margin: '0 13px',
    '&.disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  close: {
    position: 'absolute',
    left: '-41px',
    top: '6px',
    width: '35px',
    height: '35px',
    fill: '#fff',
  },
  icon: {
    color: theme.palette.primaryColor,
    width: '16px',
    flexShrink: 0,
  },
  itemDrawer: {
    zIndex: '1300!important',
  },
  searchButton: {
    bottom: '82px!important',
    zIndex: 1201,
    ...theme.overrides.searchButton,
  },
}));

function DetailSearchModal(props) {
  const {
    search,
    setSearch,
    set,
    setMulti,
    reset,
    isOpenArea,
    setIsOpenArea,
    isOpenStation,
    setIsOpenStation,
    isOpenUserTree,
    setIsOpenUserTree,
    isAvoidSharedCustomer,
    setAllCustomer,
    divisionId,
    responseClearCount,
    res,
    setRes,
    isFromAllmail,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const localStyle = dSearchLocalStyle();

  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);
  const { responseHeader } = useStore();

  const { functions } = useCustomerMain();
  const [currentUser, setCurrentUser] = useState({ userId: '', userName: '' });
  const [oldUser, setOldUser] = useState({ userId: '', userName: '' });
  const [beforeUser, setBeforeUser] = useState({ userId: '', userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divisionId: 0, divisionName: '' });
  const dispatch = useDispatch();
  const [switchSelectUser, setSwitchSelectUser] = useState({
    state: null,
    setState: null,
  });
  const [openDialogValue, setOpenDialogValue] = useState({});
  const [openDialogData, setOpenDialogData] = useState({
    user: { userId: '', userName: '' },
    division: { divId: '', divisionName: '' },
  });

  // user選択時に元Stateにデータをset
  useEffect(() => {
    const copyObj = Object.assign({}, search);
    copyObj.currentUserId = currentUser.userId ? currentUser.userId : '';
    copyObj.currentUser = currentUser.userName ? currentUser.userName : '';
    copyObj.formerUserId = oldUser.userId ? oldUser.userId : '';
    copyObj.formerUser = oldUser.userName ? oldUser.userName : '';
    copyObj.previousUserId = beforeUser.userId ? beforeUser.userId : '';
    copyObj.previousUser = beforeUser.userName ? beforeUser.userName : '';
    setSearch(copyObj);
  }, [currentUser, oldUser, beforeUser]);

  // 希望地域初期値
  const [initialParamArea, setInitialParamArea] = useState({
    wishArea: search.wishArea,
    wishAreaIds: search.wishAreaIds,
    wishAreaCode: search.wishAreaCode,
    isNoneWishArea: search.isNoneWishArea,
  });

  // 地域選択画面を開いたときに反映させる
  useEffect(() => {
    if (isOpenArea) {
      setInitialParamArea({
        wishArea: search.wishArea,
        wishAreaIds: search.wishAreaIds,
        wishAreaCode: search.wishAreaCode,
        isNoneWishArea: search.isNoneWishArea,
      });
    }
  }, [isOpenArea]);

  // 地域選択確定後、内容を反響ツリーstateに反映
  const onSelectArea = (data) => {
    setInitialParamArea(data);
    const copyObj = Object.assign({}, search);
    copyObj.wishAreaCode = data.wishAreaCode ? [data.wishAreaCode] : null;
    copyObj.wishAreaIds = data.wishAreaIds;
    copyObj.wishArea = data.wishArea;
    copyObj.isNoneWishArea = data.isNoneWishArea ? 1 : null;
    setSearch(copyObj);
  };

  const onSelectRailwaysStation = (data) => {
    console.log(selectDivision);
    console.log('gotten data:', data);
    const copyObj = Object.assign({}, search);
    copyObj.wishRailwayId = data.railway.railwayId;
    copyObj.wishRailway = data.railway.railwayName;
    copyObj.wishStationFrom = data.fromStation.stationId;
    copyObj.wishStationFromName = data.fromStation.name;
    copyObj.wishStationTo = data.toStation.stationId;
    copyObj.wishStationToName = data.toStation.name;
    setSearch(copyObj);
  };

  // 保存ボタン disabled
  const [keepDisabled, setKeepDisabled] = useState(false);

  const toProps = {
    search,
    setSearch,
    set,
    setMulti,
    isOpenArea,
    setIsOpenArea,
    isOpenStation,
    setIsOpenStation,
    isOpenUserTree,
    setIsOpenUserTree,
    isAvoidSharedCustomer,
    setKeepDisabled,
    res,
    setRes,
    isFromAllmail,
  };

  const switchingTxt = (vl) => {
    switch (vl.key) {
      case 'customerIds':
        return vl.input.state.filter(e => Number.isInteger(e)).toString();
      case 'keywordSearch':
      case 'floorPlan':
      case 'holidayWeekday':
      case 'emailStatusCodes':
      case 'actionSearchCode':
      case 'noContactDetailReasonCode':
      case 'noneContactKeywordCodes':
        return vl.input.state.map(e => { return vl.input.constants.menus[e] + ' '; });
      default:
        return vl.input.state;
    }
  };

  // UserTreeをOK押下時
  const handleSelectFunction = () => {
    console.log('switchSelectUser.state ////', switchSelectUser.state);
    const switchObj = {};
    if (openDialogValue.key === 'currentUser') {
      switchObj.state = currentUser;
    } else if (openDialogValue.key === 'formerUser') {
      switchObj.state = oldUser;
    } else if (openDialogValue.key === 'previousUser') {
      switchObj.state = beforeUser;
    }
    setOpenDialogData({
      ...openDialogData,
      user: switchObj.state,
    });
  };

  // UserTreeを選択解除押下時
  const handleClearFunction = (isInitial) => {
    if (!isInitial) {
      // 選択解除の時
      setOpenDialogData({
        ...openDialogData,
        user: { userId: '', userName: '' },
        division: setSelectDivision({ divId: '', divisionName: '' }),
      });
    }
  };

  // ダイアログ展開
  const openDialog = async (val) => {
    if (['responseCode1Sp', 'currentUser', 'formerUser', 'previousUser'].includes(val.key)) {
      // 未選択ならログインユーザー/選択済みなら選択ユーザーをset
      const current = { userId: responseHeader.userId, userName: responseHeader.userName };
      if (val.key === 'currentUser') {
        setOpenDialogData({
          ...openDialogData,
          user: currentUser,
        });
        if (!currentUser?.userId) {
          setCurrentUser(current);
        }
      } else if (val.key === 'formerUser') {
        setOpenDialogData({
          ...openDialogData,
          user: oldUser,
        });
        if (!oldUser?.userId) {
          setOldUser(current);
        }
      } else if (val.key === 'previousUser') {
        setOpenDialogData({
          ...openDialogData,
          user: beforeUser,
        });
        if (!beforeUser?.userId) {
          setBeforeUser(current);
        }
      }
    }
    setOpenDialogValue(val);
  };

  // 一つ遅らせている：openDialogで設定した値を反映後にUserTreeを起動しないと、初期値が入らないため
  useEffect(() => {
    if (!Object.entries(openDialogValue).length) {
      return;
    }
    if (['responseCode1Sp', 'currentUser', 'formerUser', 'previousUser'].includes(openDialogValue.key)) {
      const switchObj = {};
      if (openDialogValue.key === 'currentUser') {
        switchObj.state = currentUser;
        switchObj.setState = setCurrentUser;
      } else if (openDialogValue.key === 'formerUser') {
        switchObj.state = oldUser;
        switchObj.setState = setOldUser;
      } else if (openDialogValue.key === 'previousUser') {
        switchObj.state = beforeUser;
        switchObj.setState = setBeforeUser;
      }
      setSwitchSelectUser(switchObj);
    }
    openDialogValue.setOpen(true);
  }, [openDialogValue]);

  // UserTreeをクローズ時
  useEffect(() => {
    if (!isOpenUserTree) {
      if (!Object.entries(openDialogValue).length) {
        return;
      }
      switchSelectUser.setState(openDialogData.user);
    }
  }, [isOpenUserTree]);

  // 表示させる項目のリストを取得
  const initialList = DetailSearchItem(toProps);

  // モーダル用
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = (val) => {
    val.setOpen(false);
    const copyObj = Object.assign({}, search);
    switch (val.type) {
      case 'selectGroup':
      case 'itemGroup':
        val.items.forEach((el) => {
          if (el.input.state instanceof Array) {
            copyObj[el.key] = [];
          } else {
            copyObj[el.key] = '';
          }
        });
        break;
      case 'checkbox':
        if (val.input.state instanceof Array) {
          copyObj[val.key] = [];
        } else {
          copyObj[val.key] = '';
        }
        break;
      case 'response':
      case 'calendar':
        val.input.keys.forEach((key) => {
          copyObj[key] = '';
        });
        break;
      default:
        copyObj[val.key] = '';
        break;
    }
    setSearch(copyObj);
  };

  const searchStart = async () => {
    setOpen(false);
    dispatch(setIsDetailSearch(true));
    // searchをもとに検索する
    if (isSP) {
      search.divisionId = divisionId;
      dispatch(updateSearchItems(search));
      const resData = await functions.getCustomerTreeResponseFunction(search, true);
      const customers = getFromAllCustomer(resData);
      if (customers) setAllCustomer(customers);
    } else {
      dispatch(updateSearchItems(search));
    }
  };

  // 子要素(パーツ)の分岐
  const switchChildType = (val) => {
    const returnChildType = [];
    let intFlg;
    switch (val.type) {
      case 'text':
      case 'textField':
        return <SearchPartsText values={val.input} />;
      case 'checkbox':
        if (val.input.checked === 1) {
          intFlg = '1';
        } else {
          intFlg = '0';
        }
        return <SearchPartsCheckbox flg={intFlg} values={val.input} />;
      case 'select':
        return <SearchPartsSelect values={val.input} />;
      case 'boolean':
        return <SearchPartsBoolean values={val.input} />;
      case 'response':
        return (
          <SearchPartsResponse
            values={val}
            responseClearCount={responseClearCount}
            handleClear={handleClear}
          />
        );
      case 'checkboxwindow':
        return <SearchCheckboxWindowComponent values={val.input} />;
      case 'acquisition':
        return <SearchAcquisition values={val} search={search} />;
      case 'label':
        return <SearchLabel values={val.input} />;
      default:
        break;
    }
    return returnChildType;
  };

  // 項目のリスト（items）をマップで回す
  const loopChildItems = (items) => {
    return (
      <Grid className={classes.loopChildItems}>
        {items.map((data) => {
          return (
            <Grid
              item
              key={data.key}
              className={`${classes.itemwrap} ${data.className}`}
              data-before={data.before}
              data-after={data.input.after}
              data-width={data.width}
            >
              {switchChildType(data)}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const switchButtonValue = (val) => {
    switch (val.type) {
      case 'text':
        return (
          <span className={classes.buttonValue}>
            {switchingTxt(val)}
          </span>
        );
      case 'select':
        return (
          <span className={classes.buttonValue}>
            {val.input.constants.menus[val.input.state]}
          </span>
        );
      case 'checkbox':
        return (
          <span className={classes.buttonValue}>
            <span>{switchingTxt(val)}</span>
            {/* {val.input.state.split('/').length === getLengthOfObject(
              val.input.constants.menus) ? (
              <span>すべて</span>
            ) : (
              <span>{val.input.state}</span>
            )} */}
          </span>
        );
      case 'textGroup':
        // length>2の場合はhidden項目を飛ばす(ex. ID等)
        return (
          <span className={classes.buttonValue}>
            {val.items[val.items.length === 2 ? 0 : 1].input.state}
            ～
            {val.items[val.items.length === 2 ? 1 : 3].input.state}
          </span>
        );
      case 'selectGroup':
        return (
          <span className={classes.buttonValue}>
            {val.items[0].input.constants.menus[val.items[0].input.state]}
            ～
            {val.items[1].input.constants.menus[val.items[1].input.state]}
          </span>
        );
      case 'itemGroup':
        return (
          <span className={classes.buttonValue}>
            {val.items.map((item) => {
              return (
                <span key={item.key}>{switchButtonValue(item)}</span>
              );
            })}
          </span>
        );
      case 'calendar':
        return (
          <span className={classes.buttonValue}>
            {val.input.state}
            ～
            {val.input.stateto}
          </span>
        );
      default:
        return true;
        // return <span className={classes.buttonValue}>{val.input.state}</span>;
    }
  };

  // ボタンパーツ
  const getButton = (o) => {
    const isNotSelected = () => {
      if (o.input !== undefined && o.input.state !== 0 && isNone(o.input.state)) return true;
      if (o.items !== undefined && o.items.every((item) => isNone(item.input.state))) return true;
      return false;
    };
    return (
      <Button
        onClick={() => { o.setOpen(!o.open); }}
        className={`${classes.itemButton} ${(o.key === 'actionSearchCode' && o.input.disabled) && 'disabled'}`}
      >
        <span className={classes.buttonLabel}>
          {o.label !== undefined ? o.label : o.input.label}
        </span>
        <span className={classes.buttonText}>
          {(isNotSelected()) ? (
            <Add className={classes.addButtonIcon} />
          ) : (
            <>
              {switchButtonValue(o)}
              <Cancel
                className={`${classes.icon} ${classes.cancel}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClear(o);
                }}
              />
            </>
          )}
        </span>
      </Button>
    );
  };

  // ドラワーパーツ
  const getDrawerTemplate = (val, parts) => {
    return (
      <CustomSimpleDrawer
        className={classes.itemDrawer}
        open={val.open}
        setOpen={val.setOpen}
        title={val.label !== undefined ? val.label : val.input.label}
        list={parts}
        foot={
          {
            buttons: [
              {
                label: 'クリア',
                classes: common.buttonsSecondary,
                handleEvent: () => {
                  return handleClear(val);
                },
              },
              {
                label: 'OK',
                classes: common.buttonsPrimary,
                handleEvent: () => {
                  return (
                    val.setOpen(false)
                  );
                },
              },
            ],
          }
        }
      />
    );
  };

  const switchType = (val) => {
    const returnType = [];
    let parts = null;
    let children = null;
    switch (val.type) {
      case 'boolean':
      case 'textField':
      case 'response':
      case 'checkboxwindow':
      case 'acquisition':
      case 'label':
        return switchChildType(val);
      case 'textGroup':
        if (isSP) return <>{getButton(val)}</>;
        return loopChildItems(val.items);
      case 'itemGroup':
        return (
          <>
            {getButton(val)}
            {getDrawerTemplate(val, loopChildItems(val.items))}
          </>
        );
      case 'selectGroup':
        parts = loopChildItems(val.items);
        if (isSP) {
          children = (
            <>
              {getButton(val, 'group')}
              {getDrawerTemplate(val, parts)}
            </>
          );
        } else {
          children = parts;
        }
        return children;
      case 'select':
        parts = isSP ? (
          <CustomDrawerList
            menus={val.input.constants.menus}
            strValue={val.input?.strValue}
            state={val.input.state}
            setState={val.input.setState}
            single={val.key === 'convenientTimes'}
            isNumber
          />
        ) : (
          <Grid className={classes.listWrap}>
            <SearchPartsSelect values={val.input} />
          </Grid>
        );
        if (isSP) {
          children = (
            <>
              {getButton(val)}
              {getDrawerTemplate(val, parts)}
            </>
          );
        } else {
          children = parts;
        }
        return children;
      case 'checkbox':
        parts = switchChildType(val);
        if (isSP) {
          children = (
            <>
              {getButton(val)}
              {getDrawerTemplate(val, parts)}
            </>
          );
        } else {
          children = parts;
        }
        return children;
      case 'text':
        parts = switchChildType(val);
        if (isSP) {
          children = (
            <>
              {getButton(val)}
              {getDrawerTemplate(val, parts)}
            </>
          );
        } else {
          children = parts;
        }
        return children;
      case 'calendar':
        if (isSP) {
          children = (
            <>
              {getButton(val)}
              <SearchPartsCalendarWindow open={val.open} setOpen={val.setOpen} values={val.input} />
            </>
          );
        } else {
          children = <SearchPartsCalendar values={val.input} />;
        }
        return children;
      case 'popup':
        return (
          isSP ? (
            <Button
              className={classes.itemButton}
              values={val.input}
              onClick={() => openDialog(val)}
            >
              {val.input.label}
              <span className={classes.buttonText}>
                {val.input.state === '' ? (
                  <Add className={classes.addButtonIcon} />
                ) : val.input.state}
              </span>
            </Button>
          ) : (
            <Button
              className={classes.popupbtn}
              values={val.input}
              onClick={() => {
                openDialog(val);
              }}
            >
              {val.input.state ? val.input.state : val.input.label}
            </Button>
          )
        );
      default:
        break;
    }
    return returnType;
  };

  // 検索項目の表示
  const items = (val) => {
    return (
      <>
        {val.map((i, index) => {
          return (
            <Grid
              item
              key={String(index)}
              className={`${classes.itemwrap} ${i.responsive ? i.responsive : ''} ${i.className}`}
              data-before={i.before}
              data-width={i.width}
            >
              {switchType(i)}
            </Grid>
          );
        })}
      </>
    );
  };

  // 各リストの表示
  const getBoxList = () => {
    return (
      <>
        {initialList.map((data, i) => {
          return (
            <Grid item key={String(i)} className={classes.box}>
              <Typography className={`${common.title4} ${classes.title4}`}>{data.label}</Typography>
              <Grid container className={`${classes.childwrap} ${data.className}`}>{items(data.items)}</Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      {isSP ? (
        <>
          {/* 検索ボタン */}
          <Button
            onClick={handleOpen}
            className={`${common.searchButton} ${classes.searchButton}`}
          >
            <SearchIcon />
          </Button>
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            className={classes.drawer}
          >
            <Close
              className={classes.close}
              onClick={handleClose}
            />
            <Grid className={classes.searchDrawer}>
              <Typography
                className={`${common.title2} ${classes.searchDrawerHeader}`}
              >
                顧客の検索
              </Typography>
              <Grid className={classes.searchDrawerList}>
                {getBoxList()}
              </Grid>
              <Grid className={classes.searchSmallContainer}>
                <Grid className={classes.searchDrawerBtnWrap}>
                  <Button
                    className={`${common.buttonsSecondary} ${classes.searchDrawerBtn}`}
                    onClick={reset}
                    type="reset"
                  >
                    クリア
                  </Button>
                  <Button
                    className={`${common.buttonsPrimary} ${classes.searchDrawerBtn} ${keepDisabled && 'disabled'}`}
                    onClick={searchStart}
                  >
                    絞り込み
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Drawer>
        </>
      ) : (
        <>
          <Button
            type="button"
            className={`${common.buttonsSecondary} ${classes.button}`}
            onClick={handleOpen}
          >
            <span>詳細検索</span>
          </Button>
          <Dialog
            title="顧客検索ウィンドウ"
            open={open}
            onClose={handleClose}
            buttons={[
              {
                label: 'リセット',
                onClick: reset,
                type: 'secondary',
              },
              {
                label: '検索',
                onClick: searchStart,
                disabled: keepDisabled,
              },
            ]}
            width="auto"
            maxWidth={1200}
            bodyClassName={localStyle.detailSearchWrap}
          >
            {getBoxList()}
          </Dialog>
        </>
      )}
      <AreaSentaku
        initialParam={initialParamArea}
        open={isOpenArea}
        onClose={() => setIsOpenArea(false)}
        onUpdate={onSelectArea}
        isFromSearch
        isNoneWishAreaInit={search.isNoneWishArea}
      />
      <SelectRailwaysAndStationsContainer
        // MEMO: 初期値入れとかないと無限ループ起こす
        initialParam={{ areaCode: AREA_CODES.KANTO }}
        onUpdate={onSelectRailwaysStation}
        open={isOpenStation}
        onClose={() => setIsOpenStation(false)}
      />
      { isOpenUserTree && (
        <UserTree
          isOpenTree={isOpenUserTree}
          setIsOpenTree={setIsOpenUserTree}
          selectUser={switchSelectUser.state}
          setSelectUser={switchSelectUser.setState}
          setSelectDivision={setSelectDivision}
          handleSelectFunction={handleSelectFunction}
          handleClearFunction={handleClearFunction}
          isDialog
          isDefaultLoginUser
        />
      )}
    </>
  );
}

export default DetailSearchModal;
